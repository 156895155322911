<template>
  <a-card :bordered="false">
    <div>
      <a-form :form="form" >
        <a-form-item label="信用证货币金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number :precision="2" style="width: 50%" v-decorator="['amount', {initialValue: data.amount}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
        </a-form-item>
        <a-form-item label="人民币金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number :precision="2" style="width: 50%" v-decorator="['amount_cny', {initialValue: data.amount_cny}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
        </a-form-item>
        <a-form-item label="信用证货币到人民币的汇率" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number :precision="2" style="width: 50%" v-decorator="['to_cny_rate', {initialValue: data.to_cny_rate}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
        </a-form-item>
        <a-form-item label="融资利率" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number :precision="2" style="width: 50%" v-decorator="['financing_rate', {initialValue: data.financing_rate}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
        </a-form-item>
        <a-form-item label="还款时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-date-picker
            v-decorator="['repayment_date', {initialValue: data.repayment_date}, {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            show-time
            format="YYYY-MM-DD"
            placeholder="还款时间"
            value-format="yyyy-MM-DD"
          />
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea :rows="4" v-decorator="['remark', {initialValue: data.remark}, {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item v-bind="buttonCol">
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import { credit_letter_repayment_detail_list, credit_letter_repayment_detail_update } from '@/api/trade_credit_letter_repayment_detail'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      currency: this.$Dictionaries.currency,
      unit: this.$Dictionaries.unit,
      data: {},
      dateFormat: 'YYYY-MM-DD',
      start_date: '',
      end_date: '',
      date_change: false
    }
  },
  mounted () {
    credit_letter_repayment_detail_list({ id: this.$route.params.id })
      .then(res => {
        console.log(res.data, '我是数据列表@')
        const list = res.data.entries
        if (list && list.length > 0) {
          this.data = list[0]
          this.data.unit = '' + this.data.unit
          this.id = this.data.id
          if (this.data.start_date === '1970-01-01') {
            this.start_date = ''
          } else {
            this.start_date = moment(this.data.start_date, this.dateFormat)
          }
          if (this.data.end_date === '1970-01-01') {
            this.end_date = ''
          } else {
            this.end_date = moment(this.data.end_date, this.dateFormat)
          }
        }
      })
  },
  methods: {
    moment,
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        if (this.date_change) {
          values.start_date = this.start_date
          values.end_date = this.end_date
        } else {
          values.start_date = this.data.start_date
          values.end_date = this.data.end_date
        }
        credit_letter_repayment_detail_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'amount', 'currency', 'amount_cny', 'method', 'pay_time', 'remark'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    onDateChange (date, dateString) {
      console.log(date, dateString)
      this.data.start_date = dateString[0]
      this.data.end_date = dateString[1]
    }
  }
}
</script>
